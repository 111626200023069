// Layout
.wrapper {
  min-width: $content-min-width;
  text-align: center;
}

// Helper classes
.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &--around {
    justify-content: space-around;
  }

  &--center {
    justify-content: center;
  }

  &--column {
    flex-direction: column;
  }

  &--padding {
    li, p {
      padding-left: $space;
      padding-right: $space;
    }
  }
}

.hidden {
  display: none;
}

.hide {
  position: absolute;
  z-index: -9999em;
}

.list {
  display: block;
  margin: $space 0 $space;

  .item {
    color: $color-blue-text;
    display: block;
    margin: 0 0 $space/2;
  }
}
