.game-image {
  height: $content-min-width/2;
  margin: 0 auto;
  width: $content-min-width/2;
  max-width: 100%;

  @include bp(sm) {
    height: $content-min-width/1.15;
    width: $content-min-width/1.15;
  }

  @include bp(xl) {
    height: $content-min-width;
    width: $content-min-width;
  }

  svg {
    height: auto;
    max-width: 100%;
    vertical-align: top;
  }
}
