.game {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  text-align: left;

  @include bp(xs) {
    padding: $space;
  }

  &-area {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: $space/2 auto $space*6;

    @include bp(sm) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: $space*4.5;
    }

    @include bp(xl) {
      margin-bottom: $space*6;
    }
  }

  &-country {
    margin: 0 auto $space;
    min-height: $content-min-width/2;
    text-align: center;
    width: 100%;

    @include bp(sm) {
      margin: 0 0 $space;
      min-height: $content-min-width/1.15;
      width: auto;
    }

    @include bp(md) {
      margin: 0 $space $space;
      min-height: $content-min-width;
    }
  }

  &-countryname {
    color: $color-green-text;
    font-size: 2.2rem;
    line-height: 1.2;
    margin: $space 0 0;

    @include bp(md) {
      margin-top: $space;
    }

    @include bp(xl) {
      font-size: 2.8rem;
    }
  }

  &-filter {
    border-top: 1px solid $border-color;
    margin-bottom: $space;
    padding-top: $space;
  }

  &-flag {
    padding: $space*2 0;

    img {
      max-height: 80px;
      max-width: 120px;

      @include bp(sm) {
        max-height: 120px;
        max-width: 180px;
      }
    }
  }

  &-form {
    margin: 0 auto;
    padding: $space;

    li {
      @include animation-appear(0.5s, $color-blue);
      color: $color-blue-text;
      display: block;
      font-family: $font-heading;
      line-height: 1.5;
      padding: $space/3 0;
      position: relative;
    }

    .label {
      display: inline-block;
      font-family: $font-main;
      left: 0;
      line-height: 1.3;
      min-width: $label-width;
      position: absolute;
      top: $space/2;
    }

    .value {
      color: $color-green-text;
      padding-left: $label-width;

      @include bp(xl) {
        padding-left: $space*8;
      }
    }

    .currencyName {
      display: none;

      .game--roundEnded & {
        display: inline;
      }
    }

    .symbol {
      font-weight: bold;
      padding-right: $space/3;
    }

    .text {
      padding-top: $space;
    }
  }

  &-clues {
    margin-top: $space;
  }

  &-guesses {
    border-top: 1px solid $color-blue;
    margin-top: $space;
    padding-top: $space;

    .list {
      margin: $space/2 0;

      .item {
        color: $color-red;
        margin: 0;
        padding: 0;

        &--correct {
          color: $color-green;
        }
      }
    }
  }

  &-intro {
    padding: $space*2 $space $space;
    text-align: center;

    @include bp(sm) {
      max-width: calc(#{$content-min-width} + #{$space*2});
    }

    @include bp(lg) {
      max-width: $content-mid-width;
    }
  }

  &-introtext {
    color: $color-brown-text;
    font-family: $font-heading;
    font-size: 1.3rem;
  }

  &-select {
    font-size: 0.9rem;
    margin-top: $space/2;
    padding: $space/2 0;

    div {
      color: $color-green-text;
    }

    @include bp(sm) {
      font-size: 1rem;
    }
  }

  &-stats {
    background: white;
    border-top: 1px solid $border-color;
    bottom: $space*3;
    left: 0;
    padding: $space;
    position: fixed;
    width: 100%;

    @include bp(sm) {
      bottom: $space*4;
    }

    span {
      display: block;
      padding: 0 $space;
      text-align: center;

      &.value {
        font-weight: bold;
        padding-top: $space/4
      }

      @include bp(sm) {
        display: inline-block;
        padding: 0 $space/8;
        text-align: left;
      }
    }
  }

  &-zone {
    padding: $space*2 0;
    width: 100%;
  }
}
