.form {
  display: block;
  min-width: $min-form-width;
  text-align: left;
  width: 100%;

  @include bp(xs) {
    padding: $space/2 0 $space $space;
  }

  @include bp(sm) {
    min-width: $content-min-width;
    width: auto;
  }

  &-select {
    div {
      border-color: $border-color;
      color: $color-text;

      &:hover,
      &:focus {
        div {
          border-color: $color-blue;
        }
      }
    }
  }
}
