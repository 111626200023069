.header {
  background-color: $color-black;
  text-align: center;

  &-inner {
    display: flex;
    justify-content: center;
  }

  &-logo {
    height: $logo-height/2;
    margin: $space $space/2 $space 0;
  }

  &-logolink {
    align-items: center;
    display: block;
    display: flex;
    color: $color-white;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;

    &:hover {
      .header-logo {
        @include animation-spin(0.5s);
      }
    }
  }

  &-title {
    color: $color-white;
    font-family: $font-heading;
    font-size: 1.4rem;
    line-height: $logo-height/2;
    padding: $space 0;
    white-space: nowrap;
  }

  @include bp(xs) {
    &-logo {
      height: $logo-height/1.5;
    }

    &-title {
      font-size: 1.8rem;
      line-height: $logo-height/1.5;
    }
  }

  @include bp(sm) {
    &-logo {
      height: $logo-height;
      margin-right: $space;
    }

    &-logolink {
      justify-content: flex-start;
      position: relative;
      right: 0.75em;
    }

    &-title {
      font-size: 2.2rem;
      line-height: $logo-height;
    }
  }
}
