.button {
  border: 1px solid $color-blue;
  border-radius: $border-radius;
  color: $color-blue-text;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  margin: $space 0 0;
  padding: $space/2 $space;

  @include bp(lg) {
    font-size: 1.2rem;
    padding: $space/2 $space;
  }

  &:hover {
    background: $color-green;
    color: $color-white;
  }

  &:focus,
  &--selected {
    background-color: $color-green;
    color: $color-white;
    outline: none;

    &:hover {
      border-color: $color-white;
      color: $color-white;
    }
  }

  &--filter {
    display: inline-block;
    margin: $space/4 $space/2 $space/4 0;
    padding-top: calc((#{$space}/3) + 2px);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.game-filter-buttons {
  max-width: 18em;

  @include bp(lg) {
    max-width: 100%;
  }
}
