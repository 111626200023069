body {
  background: $color-white;
  color: $color-text;
  font-family: $font-main;
  font-size: 1rem;

  @include bp(xl) {
    font-size: 1.3rem;
  }
}

a {
  color: $color-green-text;
  text-decoration: none;

  &:focus, &:hover {
    color: $color-brown-text;
  }
}

h1, h2, h3 {
  line-height: 1.3;
}

sup {
  font-size: 0.6rem;
  position: relative;
  top: -$space/4;
}

.italic {
  font-style: italic;
}

.text {
  font-size: 0.9rem;
  line-height: 1.3;
  padding-bottom: $space/2;

  @include bp(xl) {
    font-size: 1.2rem;
  }

  &--blue {
    color: $color-blue-text;
  }

  &--brown {
    color: $color-brown-text;
  }

  &--green {
    color: $color-green-text;
  }

  &-large {
    font-size: 1.2rem;
  }

  &-icon {
    display: none;

    &--mobile {
      display: inline;
    }

    @include bp(xs) {
      display: inline;

      &--mobile {
        display: none;
      }
    }
  }
}
