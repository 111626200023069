$color-black: #15161a;
$color-blue: #a0c2de;
$color-blue-light: #add8e6;
$color-blue-text: #2E5984;
$color-brown: #7d7165;
$color-brown-text: #77695F;
$color-green: #79c050;
$color-green-text: #385C23;
$color-grey: #c5c5c5;
$color-light-grey: #e5e5e5;
$color-red: #B22222;
$color-text: #636363;
$color-white: #f4f4f4;

$color-bg: $color-white;

$font-heading: Verdana, Geneva, sans-serif;
$font-main: "Trebuchet MS", Helvetica, sans-serif;

$border-color: $color-light-grey;
$border-radius: 4px;

$content-min-width: 19em;
$content-mid-width: 38em;
$content-max-width: 76em;
$min-form-width: calc(#{$content-min-width} - 3rem);

$label-width: 6rem;
$logo-height: 3.33rem;

$space: 1rem;

$bp-xs: 30em; // 480px
$bp-sm: 33.75em; // 540px
$bp-md: 48em; // 768px
$bp-lg: 62em; // 992px
$bp-xl: 75em; // 1200px
